import React from "react"
import styled from "styled-components"

import Page from "../../components/page"
import { ThreeColumnsGrid } from "../../components/containers"

const Card = styled.div`
  padding: 32px;

  border: 1px solid #dedede;
  border-radius: 4px;
  text-align: center;

  background-color: #fafafa;
  color: black !important;

  &:hover {
    background-color: #ff543a;
    color: white !important;
  }
`

const ResourcesPage = () => (
  <Page title="Risorse">
    <section>
      <h1>Risorse</h1>
      <ThreeColumnsGrid>
        <a href="risorse/calcolatori">
          <Card>Calcolatori</Card>
        </a>
        <a href="risorse/libri">
          <Card>Libri</Card>
        </a>
      </ThreeColumnsGrid>
    </section>
  </Page>
)

export default ResourcesPage
